import Api from '@api';

import {YN, PAGER, INV_TRAN_FROM_STATUS, ORDER_HEADER_STATUS} from '@constant';
import {INV_TRAN_QC_STATUS, SHIPMENT_LOCK_STATUS} from "../../../../../../constant";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

/**
 * @description 列表和筛选表单不会展示的组
 */
export const noFilterGroup = ['DemandOrderPrefix', 'OrderPrefix', 'PreadvicePrefix', 'UPIPrefix'];

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [{
      "key": "TO_LOC_ID",
      "label": "目的位置",
      "type": "input",
      "placeholder": "请输入目的位置",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "TO_SITE_ID",
      "label": "目的站点",
      "type": "input",
      "placeholder": "请输入目的站点",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONSIGNMENT",
      "label": "运单",
      "type": "input",
      "placeholder": "请输入运单",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    },{
      "key": "TO_STATUS",
      "label": "目的状态",
      "type": "select",
      "placeholder": "请选择目的状态",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "options": INV_TRAN_FROM_STATUS
    }, {
      "key": "TRACKING_LEVEL",
      "label": "单位",
      "type": "input",
      "placeholder": "请输入单位",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "UPDATE_QTY",
      "label": "更新数量",
      "type": "input",
      "placeholder": "请输入更新数量",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "ARCHIVED",
      "label": "存档",
      "type": "select",
      "placeholder": "请选择存档",
      "showBlock": ["all"],
      "checked": false,
      "options": YN
    }, {
      "key": "ASN_ID",
      "label": "预到货通知单",
      "type": "input",
      "placeholder": "请输入预到货通知单",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "BATCH_ID",
      "label": "批次",
      "type": "input",
      "placeholder": "请输入批次",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CE_AVAIL_STATUS",
      "label": "",
      "type": "select",
      "placeholder": "请选择",
      "showBlock": ["all"],
      "checked": false,
      "options": YN
    }, {
      "key": "CE_COLLI_COUNT",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_COLLI_COUNT_EXPECTED",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_CONSIGNMENT_ID",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_COO",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_CWC",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_DOCUMENT_DSTAMP",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "CE_DUTY_STAMP",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_INVOICE_NUMBER",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_ORIG_ROTATION_ID",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_ORIGINATOR",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_ORIGINATOR_REFERENCE",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_RECEIPT_TYPE",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_ROTATION_ID",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_SEALS_OK",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_UCR",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_UNDER_BOND",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CLIENT_ID",
      "label": "客户端",
      "type": "magnifier",
      "placeholder": "请选择客户端",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CODE",
      "label": "代码",
      "type": "input",
      "placeholder": "请输入代码",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "COMPLETE_DSTAMP",
      "label": "完成日期",
      "type": "date",
      "placeholder": "请选择完成日期",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONDITION_ID",
      "label": "条件",
      "type": "magnifier",
      "placeholder": "请输入条件",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONFIG_ID",
      "label": "打包配置",
      "type": "input",
      "placeholder": "请输入打包配置",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    },  {
      "key": "CONSOL_LINK",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONTAINER_ID",
      "label": "箱号",
      "type": "input",
      "placeholder": "请输入箱号",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONTAINER_TYPE",
      "label": "货箱类型 ",
      "type": "input",
      "placeholder": "请输入货箱类型 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CUSTOMER_ID",
      "label": "客户",
      "type": "input",
      "placeholder": "请输入客户",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CUSTOMER_SHIPMENT_NUMBER",
      "label": "客户发货单编号 ",
      "type": "input",
      "placeholder": "请输入客户发货单编号 ",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "DOCK_DOOR_ID",
      "label": "发货门",
      "type": "input",
      "placeholder": "请输入发货门",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "DSTAMP",
      "label": "交易日期",
      "type": "date",
      "placeholder": "请选择交易日期",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "TIME",
      "label": "交易时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择交易时间",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "ELAPSED_TIME",
      "label": "经过的时间 ",
      "type": "date",
      "placeholder": "请选择经过的时间 ",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "ESTIMATED_TIME",
      "label": "估计时间 ",
      "type": "date",
      "placeholder": "请选择估计时间 ",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "EXPIRY_DSTAMP",
      "label": "有效期",
      "type": "date",
      "placeholder": "请选择有效期",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "EXTRA_NOTES",
      "label": "额外备注",
      "type": "input",
      "placeholder": "请输入额外备注",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "FINAL_LOC_ID",
      "label": "最终位置",
      "type": "input",
      "placeholder": "请输入最终位置",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "FROM_LOC_ID",
      "label": "来源位置",
      "type": "input",
      "placeholder": "请输入来源位置",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "FROM_SITE_ID",
      "label": "发货站点",
      "type": "input",
      "placeholder": "请输入发货站点",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "FROM_STATUS",
      "label": "源状态 ",
      "type": "select",
      "placeholder": "请选择源状态 ",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false,
      "options": ORDER_HEADER_STATUS
    }, {
      "key": "GRID_PICK",
      "label": "播种",
      "type": "select",
      "placeholder": "请选择播种",
      "showBlock": [],
      "checked": false,
      "options": YN
    }, {
      "key": "GRN",
      "label": "",
      "type": "numrange",
      "placeholder": "请选择",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "GROUP_ID",
      "label": "用户组",
      "type": "input",
      "placeholder": "请输入用户组",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "JOB_CLIENT_ID",
      "label": "工作客户端ID ",
      "type": "magnifier",
      "placeholder": "请选择工作客户端ID ",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
    }, {
      "key": "JOB_ID",
      "label": "工作标识 ",
      "type": "input",
      "placeholder": "请输入工作标识 ",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "JOB_SITE_ID",
      "label": "工作站点ID ",
      "type": "input",
      "placeholder": "请选择工作站点ID ",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "JOB_UNIT",
      "label": "工作单元 ",
      "type": "input",
      "placeholder": "请输入工作单元 ",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "KEY",
      "label": "",
      "type": "numrange",
      "placeholder": "请选择",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "KIT_PLAN_ID",
      "label": "套装计划",
      "type": "input",
      "placeholder": "请输入套装计划",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LABOR_ASSIGNMENT",
      "label": "",
      "type": "numrange",
      "placeholder": "请选择",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LABOR_GRID_SEQUENCE",
      "label": "",
      "type": "numrange",
      "placeholder": "请选择",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LINE_ID",
      "label": "行号",
      "type": "numrange",
      "placeholder": "请选择行号",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LIST_ID",
      "label": "列表号",
      "type": "input",
      "placeholder": "请输入列表号",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LOCK_CODE",
      "label": "锁定 代码 ",
      "type": "magnifier",
      "placeholder": "请输入锁定 代码 ",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LOCK_STATUS",
      "label": "锁状态",
      "type": "select",
      "placeholder": "请选择锁状态",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "options": SHIPMENT_LOCK_STATUS,
      "formatter": ({cellValue, row, column}) => {
        let res = SHIPMENT_LOCK_STATUS.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "MANNING",
      "label": "人员配置 ",
      "type": "input",
      "placeholder": "请输入人员配置 ",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": [],
      "checked": false
    }, {
      "key": "MANUF_DSTAMP",
      "label": "生产日期 ",
      "type": "date",
      "placeholder": "请选择生产日期 ",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MASTER_ORDER_ID",
      "label": "主订单号",
      "type": "input",
      "placeholder": "请输入主订单号",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MASTER_ORDER_LINE_ID",
      "label": "主订单行号",
      "type": "numrange",
      "placeholder": "请选择主订单行号",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MASTER_PAH_ID",
      "label": "主收货单号",
      "type": "input",
      "placeholder": "请输入主收货单号",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MASTER_PAL_ID",
      "label": "主收货单行号",
      "type": "numrange",
      "placeholder": "请选择主收货单行号",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "NOTES",
      "label": "备注",
      "type": "input",
      "placeholder": "请输入备注",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_CHK_1",
      "label": "用户自定义的检查1 ",
      "type": "input",
      "placeholder": "请输入用户自定义的检查1 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    },{
      "key": "OLD_USER_DEF_CHK_1",
      "label": "用户自定义的检查1 ",
      "type": "input",
      "placeholder": "请输入用户自定义的检查1 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_CHK_2",
      "label": "用户自定义的检查2 ",
      "type": "input",
      "placeholder": "请输入用户自定义的检查2 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_CHK_3",
      "label": "用户自定义的检查3 ",
      "type": "input",
      "placeholder": "请输入用户自定义的检查3 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_CHK_4",
      "label": "用户自定义的检查4 ",
      "type": "input",
      "placeholder": "请输入用户自定义的检查4 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_DATE_1",
      "label": "用户自定义日期 1 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 1 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_DATE_2",
      "label": "用户自定义日期 2 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 2 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_DATE_3",
      "label": "用户自定义日期 3 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 3 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_DATE_4",
      "label": "用户自定义日期 4 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 4 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_NOTE_1",
      "label": "用户自定义注释 1 ",
      "type": "input",
      "placeholder": "请输入用户自定义注释 1 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_NOTE_2",
      "label": "用户自定义注释 2 ",
      "type": "input",
      "placeholder": "请输入用户自定义注释 2 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_NUM_1",
      "label": "用户自定义号码 1 ",
      "type": "input",
      "placeholder": "请输入用户自定义号码 1 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_NUM_2",
      "label": "用户自定义号码 2",
      "type": "input",
      "placeholder": "请输入用户自定义号码 2",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_NUM_3",
      "label": "用户自定义号码 3 ",
      "type": "input",
      "placeholder": "请输入用户自定义号码 3 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_NUM_4",
      "label": "用户自定义号码 4 ",
      "type": "input",
      "placeholder": "请输入用户自定义号码 4 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_TYPE_1",
      "label": "用户自定义类型 1 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 1 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_TYPE_2",
      "label": "用户自定义类型 2 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 2 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_TYPE_3",
      "label": "用户自定义类型 3 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 3 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_TYPE_4",
      "label": "用户自定义类型 4 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 4 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_TYPE_5",
      "label": "用户自定义类型 5 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 5 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_TYPE_6",
      "label": "用户自定义类型 6 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 6 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_TYPE_7",
      "label": "用户自定义类型 7 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 7 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "OLD_USER_DEF_TYPE_8",
      "label": "用户自定义类型 8 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 8 ",
      "tabGroup": "Old User Defined _ Setting 1",
      "tabGroupName": "旧用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "ORIGIN_ID",
      "label": "产地",
      "type": "magnifier",
      "placeholder": "请输入产地",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "ORIGINAL_QTY",
      "label": "原始数量 ",
      "type": "input",
      "placeholder": "请输入原始数量 ",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "OWNER_ID",
      "label": "货主",
      "type": "magnifier",
      "placeholder": "请选择货主",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      "options": []
    }, {
      "key": "PALLET_CONFIG",
      "label": "托盘类型 ",
      "type": "input",
      "placeholder": "请输入托盘类型 ",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PALLET_GROUPED",
      "label": "组合托盘",
      "type": "select",
      "placeholder": "请选择组合托盘",
      "showBlock": ["all"],
      "checked": false,
      "options": YN
    }, {
      "key": "PALLET_ID",
      "label": "托盘号码",
      "type": "input",
      "placeholder": "请输入托盘号码",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PF_CONSOL_LINK",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "PLAN_SEQUENCE",
      "label": "计划顺序",
      "type": "input",
      "placeholder": "请输入计划顺序",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": [],
      "checked": false
    }, {
      "key": "PRINT_LABEL_ID",
      "label": "拣选标签",
      "type": "input",
      "placeholder": "请输入拣选标签",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": [],
      "checked": false
    }, {
      "key": "QC_STATUS",
      "label": "质检状态",
      "type": "select",
      "placeholder": "请选择质检状态",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "options": INV_TRAN_QC_STATUS
    }, {
      "key": "RDT_USER_MODE",
      "label": "RDT用户模式",
      "type": "input",
      "placeholder": "请输入RDT用户模式",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": [],
      "checked": false
    }, {
      "key": "REASON_ID",
      "label": "原因",
      "type": "input",
      "placeholder": "请输入原因",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "REFERENCE_ID",
      "label": "单号",
      "type": "input",
      "placeholder": "请输入单号",
      "tabGroup": "General",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SAMPLING_TYPE",
      "label": "质检类型",
      "type": "input",
      "placeholder": "请输入质检类型",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SAP_IDOC_TYPE",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "SAP_TID",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "SESSION_TYPE",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "SHIFT",
      "label": "班次",
      "type": "input",
      "placeholder": "请输入班次",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SHIP_DOCK",
      "label": "发货码头",
      "type": "input",
      "placeholder": "请输入发货码头",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SHIPMENT_NUMBER",
      "label": "发货单号",
      "type": "input",
      "placeholder": "请输入发货单号",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SITE_ID",
      "label": "站点",
      "type": "select",
      "placeholder": "请选择站点",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      "options": async () => {
        let {status, data: {list}, message} = await Api.setSelectSiteList({
          "SITE": {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item => {
            return {
              key: item.SITE_ID,
              label: item.SITE_ID
            }
          })
        }
      },
    }, {
      "key": "SKU_ID",
      "label": "SKU",
      "type": "magnifier",
      "placeholder": "请输入SKU",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false
    },{
      "key": "_DESCRIPTION_",
      "label": "描述",
      "type": "textarea",
      "placeholder": "",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": [
        "dataInfo"
      ],
      "col": "2",
    }, {
      "key": "SPEC_CODE",
      "label": "规格代码 ",
      "type": "input",
      "placeholder": "请输入规格代码 ",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "STAGE_ROUTE_ID",
      "label": "分段路线ID ",
      "type": "input",
      "placeholder": "请输入分段路线ID ",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "STAGE_ROUTE_SEQUENCE",
      "label": "分段路线顺序",
      "type": "numrange",
      "placeholder": "请选择分段路线顺序",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "STATION_ID",
      "label": "设备号",
      "type": "input",
      "placeholder": "请输入设备号",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SUMMARY_RECORD",
      "label": "摘要记录 ",
      "type": "select",
      "placeholder": "请选择摘要记录 ",
      "showBlock": ["all"],
      "checked": false,
      "options": YN
    }, {
      "key": "SUPPLIER_ID",
      "label": "供应商",
      "type": "input",
      "placeholder": "请输入供应商",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "TAG_ID",
      "label": "Tag标识 ",
      "type": "input",
      "placeholder": "请输入Tag标识 ",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "TASK_CATEGORY",
      "label": "任务种类 ",
      "type": "input",
      "placeholder": "请输入任务种类 ",
      "showBlock": ["dataInfo"],
      "checked": false
    },{
      "key": "UPLOADED",
      "label": "上传到主系统",
      "type": "select",
      "placeholder": "请选择上传到主系统",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": [],
      "checked": false,
      "options": YN
    }, {
      "key": "UPLOADED_AB",
      "label": "上传到计费系统",
      "type": "select",
      "placeholder": "请选择上传到计费系统",
      "showBlock": [],
      "checked": false,
      "options": YN
    }, {
      "key": "UPLOADED_CUSTOMS",
      "label": "上传到海关系统",
      "type": "select",
      "placeholder": "请选择上传到海关系统",
      "showBlock": [],
      "checked": false,
      "options": YN
    }, {
      "key": "UPLOADED_DSTAMP",
      "label": "上传日期",
      "type": "data",
      "placeholder": "请输入上传日期",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ['all'],
      "checked": false
    }, {
      "key": "UPLOADED_TIME",
      "label": "上传时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请输入上传时间",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": ['dataInfo'],
      "checked": false
    }, {
      "key": "UPLOADED_FILENAME",
      "label": "上传文件",
      "type": "input",
      "placeholder": "请输入上传文件",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": [],
      "checked": false
    }, {
      "key": "UPLOADED_LABOR",
      "label": "上传到劳动力系统",
      "type": "input",
      "placeholder": "请输入上传到劳动力系统",
      "showBlock": [],
      "checked": false
    }, {
      "key": "UPLOADED_TM",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "UPLOADED_VVIEW",
      "label": "",
      "type": "input",
      "placeholder": "请输入",
      "showBlock": [],
      "checked": false
    }, {
      "key": "UPLOADED_WS2PC_ID",
      "label": "Uploaded_Ws2pc_Id",
      "type": "input",
      "placeholder": "请输入Uploaded_Ws2pc_Id",
      "tabGroup": "Miscellaneous 2",
      "tabGroupName": "进口税和消费税 ",
      "showBlock": [],
      "checked": false
    }, {
      "key": "USER_DEF_CHK_1",
      "label": "用户自定义的检查1 ",
      "type": "input",
      "placeholder": "请输入用户自定义的检查1 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_CHK_2",
      "label": "用户自定义的检查2 ",
      "type": "input",
      "placeholder": "请输入用户自定义的检查2 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_CHK_3",
      "label": "用户自定义的检查3 ",
      "type": "input",
      "placeholder": "请输入用户自定义的检查3 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_CHK_4",
      "label": "用户自定义的检查4 ",
      "type": "input",
      "placeholder": "请输入用户自定义的检查4 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_DATE_1",
      "label": "用户自定义日期 1 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 1 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_DATE_2",
      "label": "用户自定义日期 2 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 2 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_DATE_3",
      "label": "用户自定义日期 3 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 3 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_DATE_4",
      "label": "用户自定义日期 4 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 4 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_NOTE_1",
      "label": "用户自定义注释 1 ",
      "type": "input",
      "placeholder": "请输入用户自定义注释 1 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "USER_DEF_NOTE_2",
      "label": "用户自定义注释 2 ",
      "type": "input",
      "placeholder": "请输入用户自定义注释 2 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "USER_DEF_NUM_1",
      "label": "用户自定义号码 1 ",
      "type": "input",
      "placeholder": "请输入用户自定义号码 1 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "USER_DEF_NUM_2",
      "label": "用户自定义号码 2",
      "type": "input",
      "placeholder": "请输入用户自定义号码 2",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "USER_DEF_NUM_3",
      "label": "用户自定义号码 3 ",
      "type": "input",
      "placeholder": "请输入用户自定义号码 3 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "USER_DEF_NUM_4",
      "label": "用户自定义号码 4 ",
      "type": "input",
      "placeholder": "请输入用户自定义号码 4 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "USER_DEF_TYPE_1",
      "label": "用户自定义类型 1 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 1 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_TYPE_2",
      "label": "用户自定义类型 2 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 2 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_TYPE_3",
      "label": "用户自定义类型 3 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 3 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_TYPE_4",
      "label": "用户自定义类型 4 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 4 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_TYPE_5",
      "label": "用户自定义类型 5 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 5 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_TYPE_6",
      "label": "用户自定义类型 6 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 6 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_TYPE_7",
      "label": "用户自定义类型 7 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 7 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_TYPE_8",
      "label": "用户自定义类型 8 ",
      "type": "input",
      "placeholder": "请输入用户自定义类型 8 ",
      "tabGroup": "User Defined _ Setting 1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_ID",
      "label": "用户",
      "type": "input",
      "placeholder": "请输入用户",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "WORK_GROUP",
      "label": "工作组",
      "type": "input",
      "placeholder": "请输入工作组",
      "tabGroup": "Miscellaneous",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }]
  }
}