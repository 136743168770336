import { getFormConfig } from './formDataConfig';
import { tab_zh } from '../../../configs/tabTranslate';
import {isCanShowBlock} from "../../../../../../utils";
import {orderData} from './orderProp'

export const formConfig = vm => {

  let configArr = getFormConfig(vm).filterFormConfig.filter(item => item.tabGroup && isCanShowBlock('dataInfo',item.showBlock));

  let thead = [], common = [];

  configArr.forEach(item => {
      if (!thead.includes(item.tabGroup)) {
          thead.push(item.tabGroup)
          item.disabled = true;
          item.placeholder = '';
          item.tabGroupName = tab_zh[item.tabGroup]
          common.push({label: item.tabGroupName, value: item.tabGroup, tagConfigs: [item]})
      } else {
        common.forEach(res => {
              if (res.value === item.tabGroup) {
                  item.disabled = true;
                  item.placeholder = '';
                  item.tabGroupName = tab_zh[item.tabGroup]
                  res.tagConfigs.push(item)
              }
          })
      }
  })

  let commmonFormConfig = common.filter(item => item.value === 'THead')
  let tagFormConfig = common.filter(item => item.value !== 'THead')

  let commmonFormConfigData =  commmonFormConfig.map(item=>{
    return {
      ...item,
      tagConfigs:[]
    }
  })

  let tagFormConfigData =  tagFormConfig.map(item=>{
    return {
      ...item,
      tagConfigs:[]
    }
  })

  orderData.forEach(item=>{
    commmonFormConfig.forEach((child,index)=>{
      let info = child.tagConfigs.find(tag=>(tag.key === item)) || {}
      if(info.key){
        commmonFormConfigData[index].tagConfigs.push(info)
      }
    })

    tagFormConfig.forEach((child,index)=>{
      let info = child.tagConfigs.find(tag=>tag.key === item) || {}
      if(info.key){
        tagFormConfigData[index].tagConfigs.push(info)
      }
    })
  })
  commmonFormConfigData[0].tagConfigs.push(commmonFormConfig[0].tagConfigs.find(item=>item.key === '_DESCRIPTION_'))
  return {
    commmonFormConfig: commmonFormConfigData,
    tagFormConfig: tagFormConfigData,
    thead
  }
};
