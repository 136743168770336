export const tab_en = {
  "THead": "THead",
  "Miscellaneous 2": "Miscellaneous 2",
  "Miscellaneous": "Miscellaneous",
  "General": "General",
  "User Defined _ Setting 1": "User Defined _ Setting 1",
  "Old User Defined _ Setting 1": "Old User Defined _ Setting 1"
};

export const tab_zh = {
  "THead": "表头",
  "Miscellaneous 2": "进口税和消费税",
  "Miscellaneous": "杂项",
  "General": "综合",
  "User Defined _ Setting 1": "用户自定义的_设置1",
  "Old User Defined _ Setting 1": "旧用户自定义的_设置1",
}